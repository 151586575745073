import axios from "axios";
import Config from "../config/Config";

export function userAuthen(username, password) {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(Config.PathApi + "/authen", formData, config);
}

export function getApproveLists(username) {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("device_center", 0);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(Config.PathApi + "/get_approve_list", formData, config);
}

export function updateSVApprove(formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(Config.PathApi + "/update_sv_approve", formData, config);
}

export function getTransDetails(id) {
  let formData = new FormData();
  formData.append("transection_id", id);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(
    Config.PathApi + "/get_transection_detail",
    formData,
    config
  );
}

import { Switch, Route, Redirect } from "react-router-dom";
import PageIndex from "../pages/PageIndex";
import PageLogin from "../pages/PageLogin";
import PageLogout from "../pages/PageLogout";

function AppRoute() {
  return (
    <Switch>
      {<Redirect exact from="/" to="/index" />}
      <Route path="/index" component={PageIndex} />
      <Route path="/login" component={PageLogin} />
      <Route path="/logout" component={PageLogout} />
    </Switch>
  );
}

export default AppRoute;

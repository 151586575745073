import { useState } from "react";
import PropTypes from "prop-types";

import { userAuthen } from "../crud/apiSparepart";

import Swal from "sweetalert2";
import useToken from "../crud/useToken";

export default function PageLogin() {
  const [user, setUser] = useState();
  const { token, setToken } = useToken();
  const [password, setPassword] = useState();
  const _onLogin = async (credentials) => {
    let rez = await userAuthen(credentials.user, credentials.password);
    if (!rez.data.error) {
      let tokenObject = { token: credentials.user.trim().toLowerCase() };
      setToken(tokenObject);
      window.location.replace("/");
    } else {
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonColor: "#df0007",
        cancelButtonText: "ตกลง",
        html: rez.data.err_msg,
      });
    }
  };
  return (
    <div>
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-4">
          <div>
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              ล็อกอินเข้าระบบ
            </h2>
          </div>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="ชื่อยูสเซอร์"
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="รหัสผ่าน"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                _onLogin({ user, password });
              }}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {/* Heroicon name: solid/lock-closed */}
                {/* <svg
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg> */}
              </span>
              ล็อกอิน
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  getApproveLists,
  getTransDetails,
  updateSVApprove,
} from "../crud/apiSparepart";
import useToken from "../crud/useToken";
import Swal from "sweetalert2";
import imgUser from "../images/user.png";
import queryString from "query-string";
function PageIndex(props) {
  const { token, setToken } = useToken();
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [navUserIsOpen, setNavUserIsOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [listApprove, setListApprove] = useState([]);

  const [actionId, setActionId] = useState("");
  const [actionRemark, setActionRemark] = useState("");
  const [transDetails, setTransDetails] = useState([]);

  let params = queryString.parse(props.location.search);
  // console.log("params=", params);

  useEffect(() => {
    let user = token;
    if (params["id"] !== undefined && params["u"] !== undefined) {
      console.log(params);
      let tokenObject = { token: params["u"].toLowerCase() };
      setToken(tokenObject);
      setActionId(params["id"]);
      _onPopup(params["id"]);
      user = params["u"];
    }

    if (user !== "" && user !== undefined) {
      async function _getApproveLists() {
        let rez = await getApproveLists(user);
        console.log(rez.data.data);
        if (!rez.data.error) {
          setListApprove(rez.data.data);
        }
      }
      _getApproveLists();
    } else {
      window.location.replace("/login");
    }
  }, []);

  const _onPopup = async (id) => {
    let rez = await getTransDetails(id);
    if (!rez.data.error) {
      await setTransDetails(rez.data.data[0]);
      console.log(rez.data.data[0]);
      setActionId(id);
      setPopupOpen(true);
    } else {
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonColor: "#df0007",
        cancelButtonText: "ตกลง",
        html: rez.err_msg,
      });
    }
  };
  const _onApprove = async (approve) => {
    console.log(actionRemark);
    let approveFlag = approve == true ? "APPROVE" : "REJECT";
    let checkError = "";
    let checkFormData = true;
    if (!approve && actionRemark === "") {
      checkFormData = false;
      checkError += "<li>กรุณากรอกหมายเหตุ</li>";
    }
    if (checkFormData) {
      let formData = new FormData();
      formData.append("tran_id", actionId);
      formData.append("approve_user", token);
      formData.append("approve_flag", approveFlag);
      formData.append("approve_remark", actionRemark);
      let rez = await updateSVApprove(formData);
      setActionId("");
      setActionRemark("");
      setPopupOpen(false);
      window.location.reload();
    } else {
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonColor: "#df0007",
        cancelButtonText: "ตกลง",
        html: "<ul>" + checkError + "</ul>",
      });
    }
  };
  return (
    <div>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0"></div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4"></div>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <div className="ml-3 relative">
                  <div>
                    <button
                      type="button"
                      className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      id="user-menu"
                      aria-expanded="false"
                      aria-haspopup="true"
                      onClick={() => {
                        setNavUserIsOpen(!navUserIsOpen);
                      }}
                    >
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={imgUser}
                        alt="user"
                      />
                    </button>
                  </div>
                  {/*
          Dropdown menu, show/hide based on menu state.

          Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
          Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        */}
                  <div
                    className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  ${
                      navUserIsOpen === true ? "" : " hidden"
                    } `}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <a
                      href="/logout"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      ออกจากระบบ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              {/* Mobile menu button */}
              <button
                type="button"
                className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => {
                  setNavIsOpen(!navIsOpen);
                }}
              >
                <span className="sr-only">Open main menu</span>
                {/*
        Heroicon name: outline/menu

        Menu open: "hidden", Menu closed: "block"
      */}
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/*
        Heroicon name: outline/x

        Menu open: "block", Menu closed: "hidden"
      */}
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu, show/hide based on menu state. */}
        <div
          className={`md:hidden  ${navIsOpen === true ? "" : " hidden"} `}
          id="mobile-menu"
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={imgUser}
                  alt="user"
                />
              </div>
              <div className="ml-3">
                <div className="text-base font-medium leading-none text-white"></div>
                <div className="text-sm font-medium leading-none text-gray-400"></div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              <a
                href="/logout"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              >
                ออกจากระบบ
              </a>
            </div>
          </div>
        </div>
      </nav>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-xl font-bold text-gray-900">หน้าหลัก</h1>
        </div>
      </header>
      <main className="mx-5">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500  whitespace-nowrap uppercase tracking-wider"
                        >
                          เลขที่การเบิก
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500  whitespace-nowrap uppercase tracking-wider"
                        >
                          อะไหล่ที่เบิก
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500  whitespace-nowrap uppercase tracking-wider"
                        >
                          วันที่เบิก
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500  whitespace-nowrap uppercase tracking-wider"
                        >
                          เบิกโดย
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500  whitespace-nowrap uppercase tracking-wider"
                        >
                          สถานะ
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {listApprove &&
                        listApprove.map((item, i) => {
                          return (
                            <tr
                              key={i}
                              className="text-sm hover:bg-blue-100 cursor-pointer"
                              onClick={() => {
                                _onPopup(item.checkout_tran_id);
                              }}
                            >
                              <td className="px-3 py-2 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="">
                                    <div className="font-medium text-gray-900">
                                      {item.checkout_tran_id}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-3 py-2">
                                <div className="text-gray-900 font-semibold">
                                  {item.part_name}
                                </div>
                                <div className="text-gray-500">
                                  {item.model_spec}
                                </div>
                              </td>
                              <td className="px-3 py-2 whitespace-nowrap">
                                {item.checkout_request_date.substr(5, 2)}/
                                {item.checkout_request_date.substr(8, 2)}
                              </td>
                              <td className="px-3 py-2 whitespace-nowrap">
                                {item.checkout_request_user.toLowerCase()}
                              </td>
                              <td className="px-3 py-2 whitespace-nowrap">
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                  รออนุมัติ
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  {popupOpen == true && (
                    <>
                      {/* This example requires Tailwind CSS v2.0+ */}
                      <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div
                            className="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                          >
                            <div className="absolute inset-0 bg-gray-500 opacity-75" />
                          </div>
                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                          >
                            ​
                          </span>
                          <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full  w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                          >
                            <button
                              className="px-3 mt-6 text-gray-500 z-40 fixed top-0 right-0  focus:outline-none"
                              onClick={() => {
                                setPopupOpen(false);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                ></path>
                              </svg>
                            </button>

                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                              <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                  {/* Heroicon name: outline/exclamation */}
                                  <svg
                                    className="h-6 w-6 text-green-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                    />
                                  </svg>
                                </div>
                                <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                  <h3
                                    className="text-lg leading-6 font-medium text-gray-900"
                                    id="modal-headline"
                                  >
                                    รายละเอียดการขอเบิก
                                  </h3>
                                  <div className="mt-2">
                                    <div className="text-sm text-gray-500  sm:text-left">
                                      <div className="mt-3 bg-white shadow overflow-hidden rounded-lg">
                                        <div className="px-4 py-3 sm:px-6">
                                          <h3 className="leading-6 font-bold ">
                                            รายละเอียดอะไหล่
                                          </h3>
                                        </div>
                                        <div className="border-t border-gray-200">
                                          <dl>
                                            <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                              <dt className="left font-medium text-gray-500">
                                                เครื่องจักร
                                              </dt>
                                              <dd className=" left text-gray-900 sm:mt-0 sm:col-span-2">
                                                {transDetails.MACHINE_NAME}
                                              </dd>
                                            </div>
                                            <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                              <dt className="left font-medium text-gray-500">
                                                เหตุผลการเบิก
                                              </dt>
                                              <dd className="left text-gray-900 sm:mt-0 sm:col-span-2">
                                                {transDetails.REASON_NAME.toUpperCase()}
                                              </dd>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                              <dt className="left font-medium text-gray-500">
                                                จำนวนที่เบิก
                                              </dt>
                                              <dd className="left text-gray-900 sm:mt-0 sm:col-span-2">
                                                {
                                                  transDetails.CHECKOUT_REQUEST_QTY
                                                }
                                              </dd>
                                            </div>
                                            <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                              <dt className="left font-medium text-gray-500">
                                                ชื่อผู้เบิก
                                              </dt>
                                              <dd className="left text-gray-900 sm:mt-0 sm:col-span-2">
                                                {
                                                  transDetails.CHECKOUT_REQUEST_USER
                                                }
                                              </dd>
                                            </div>
                                            <div className="bg-gray-50 px-4  py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                              <dt className="left font-medium text-gray-500">
                                                สถานะที่ส่ง
                                              </dt>
                                              <dd className="left text-gray-900 sm:mt-0 sm:col-span-2">
                                                {
                                                  transDetails.CHECKOUT_DELI_PLACE
                                                }
                                              </dd>
                                            </div>
                                            <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                              <dt className="left font-medium text-gray-500">
                                                วันและเวลาที่เบิก
                                              </dt>
                                              <dd className="left text-gray-900 sm:mt-0 sm:col-span-2">
                                                {transDetails.CHECKOUT_DATETIME_REQUEST.substr(
                                                  0,
                                                  19
                                                )}
                                              </dd>
                                            </div>
                                            <div className="bg-gray-50 px-4  py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                              <dt className="left font-medium text-gray-500">
                                                หมายเหตุจากผู้เบิก
                                              </dt>
                                              <dd className="left text-gray-900 sm:mt-0 sm:col-span-2">
                                                {
                                                  transDetails.CHECKOUT_REQUEST_REMARK
                                                }
                                              </dd>
                                            </div>
                                          </dl>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mt-2">
                                    <div className="my-3">
                                      หมายเหตุ / คอมเมนต์
                                    </div>

                                    <textarea
                                      className="w-full border p-3"
                                      onChange={(e) => {
                                        setActionRemark(e.target.value);
                                      }}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                className=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-2 sm:w-auto sm:text-sm"
                                onClick={() => {
                                  _onApprove(true);
                                }}
                              >
                                อนุมัติ
                              </button>
                              <button
                                type="button"
                                className="mt-1 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0  sm:ml-2 sm:w-auto sm:text-sm"
                                onClick={() => {
                                  _onApprove(false);
                                }}
                              >
                                ไม่อนุมัติ
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PageIndex;
